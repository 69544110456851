import type { AnalyticsPlugin } from "analytics";
import type { Properties } from "posthog-js";
import posthog from "posthog-js?client";

// Coming from vite config in app.config.ts
declare const POSTHOG_API_KEY: string;

export const PostHogHost = "https://us.i.posthog.com";

let isPostHogLoaded = false;

export const posthogAnalyticsPlugin = (): AnalyticsPlugin => {
  const plugin: AnalyticsPlugin = {
    name: "posthog",
    initialize: () => {
      if (import.meta.env.SSR) return;

      posthog.init(POSTHOG_API_KEY, {
        api_host: PostHogHost,
        loaded: () => {
          isPostHogLoaded = true;
        },
        bootstrap: {
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          featureFlags: (window as any).__ST_EXPERIMENTS_BOOTSTRAP__,
        },
      });
    },
    identify: ({
      payload,
    }: {
      payload: {
        userId: string;
        traits: Properties;
      };
    }) => {
      posthog.identify(payload.userId, payload.traits);
    },
    track: (event: {
      payload: { event: string; properties: Properties };
    }) => {
      posthog.capture(event.payload.event, event.payload.properties);
    },
    page: ({ payload }: { payload: Properties }): void => {
      posthog.capture("$pageview", payload.properties);
    },
    loaded: () => {
      // Don't send analytics events on the server
      if (import.meta.env.SSR) return false;

      return isPostHogLoaded;
    },
    methods: {
      reset() {
        posthog.reset();
      },
      setPersonProperties(properties: Properties) {
        posthog.setPersonProperties(properties);
      },
    },
  };

  return plugin;
};
