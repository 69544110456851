import type { Logger, NamedLogger } from "./types";

const combine = (a: string, b: string) => `${a}.${b}`;

/** NamedLogger wraps the provided Logger and adds the caller name to each log message. */
export class Named implements NamedLogger {
  constructor(
    readonly logger: Logger,
    readonly caller: string,
  ) {}

  error(errCode: string, message: string, error: Error | unknown, optionalParams?: Record<string, unknown>) {
    this.logger.error(errCode, this.caller, message, error, optionalParams);
  }

  kind() {
    return `NamedLogger ${this.logger.kind()}`;
  }

  debug(message: string, optionalParams?: Record<string, unknown>) {
    this.logger.debug(this.caller, message, optionalParams);
  }

  info(message: string, optionalParams?: Record<string, unknown>) {
    this.logger.info(this.caller, message, optionalParams);
  }

  log(message: string, optionalParams?: Record<string, unknown>) {
    this.logger.log(this.caller, message, optionalParams);
  }

  trace(message: string, optionalParams?: Record<string, unknown>) {
    this.logger.trace(this.caller, message, optionalParams);
  }

  warn(message: string, optionalParams?: Record<string, unknown>) {
    this.logger.warn(this.caller, message, optionalParams);
  }

  child(caller: string) {
    return new Named(this.logger, combine(this.caller, caller));
  }
}
