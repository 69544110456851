export const identity = {
  newUserDefaults: {
    organization: {
      displayName: "My Organization",
      name: "My Organization",
      slug: "my-organization",
    },
    project: {
      displayName: "Default Project",
      name: "My Default Project",
      slug: "default-project",
    },
  },
};

export type IdentityDomainDictionary = typeof identity;
