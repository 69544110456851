export function encodeBase64URI(str: string) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode(Number.parseInt(`0x${p1}`, 16));
    }),
  );
}

export function decodeBase64URI(str: string) {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(""),
  );
}
