export const Locales = {
  en: "English",
  fr: "Français",
  es: "Español",
  de: "Deutsch",
} as const;

type ObjectValues<T extends object> = T[keyof T];
export type Locale = ObjectValues<typeof Locales>;
export type LocaleKey = keyof typeof Locales;

export type LocaleStore = {
  locale: LocaleKey;
  error?: string;
  library: Record<string, unknown>;
};
