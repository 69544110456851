import { createSignal } from "solid-js";
import { isServer } from "solid-js/web";
import type { Logger } from "@repo/logger";
import type { Dictionary } from "~/domains/i18n/dictionary/en";
import { cookieStorage, makePersisted } from "@solid-primitives/storage";
import { Xid } from "@repo/id";

export type DeviceServiceDependencies = {
  logger: Logger;
  dictionary: Dictionary;
};

const UNKNOWN_DEVICE_ID = "device_coepl4pt7098rgkd7ov0";

export const useDeviceService = (deps: DeviceServiceDependencies) => {
  const [getDeviceId, setDeviceId] = makePersisted(createSignal<string>(), {
    name: "st_did",
    storage: cookieStorage,
    storageOptions: { expires: new Date(+new Date() + 3.1536e12) }, // 100-years
  });

  if (isServer && !getDeviceId()) {
    setDeviceId(`device_${new Xid().toString()}`);
  }

  return {
    getDeviceId: () => getDeviceId() ?? UNKNOWN_DEVICE_ID,
  };
};
