import {
  AssetLifecycleStates,
  type ThreadMessage,
  isMessageKnowledgeV1,
  isMessagePrompt,
  isMessageTextV1,
  type curator,
} from "@repo/client";
import type { ThreadStore } from "../types";
import campaigns from "../../marketing/data/campaigns.gen.json";

export const getThreadTransformationId = (messages: ThreadMessage[]) => {
  const prompt = messages.find((t) => isMessagePrompt(t)) as curator.MessagePromptV1 | undefined;
  return prompt?.transformationId;
};

export const getThreadCampaignPrompt = (transformationId: string) =>
  campaigns.prompts.find((p) => p.id === transformationId);

/**
 * Handles checking for auto-submitting a campaign's prompt after a user has uploaded a file
 *
 * These are the following conditions that need to happen for it to auto-submit:
 *
 * - the thread has been started with a transformationId (one of the predefined prompts from the landing pages)
 * - the campaign has the `requiresUpload` property as true
 * - the user hasn't deviated from the predefined prompt and storytell has responded with a predefined message (checked through `provenance.processedByModel`)
 * - the user has uploaded / added exactly one file after the predefiend response
 * - the file is `ready`
 * - a prompt hasn't already been auto-submitted for this thread
 */
export const getThreadAutoSubmitCampaignPrompt = (
  messages: ThreadMessage[],
  prompt: ThreadStore["computed"]["transformationCampaign"],
) => {
  if (!prompt) return;
  if (!prompt.requiresUpload) return;
  if (messages.length !== 3) return;

  const response = messages[1];
  const knowledgeChange = messages[2];
  if (!isMessageTextV1(response)) return;
  if (!isMessageKnowledgeV1(knowledgeChange)) return;

  if (!response.provenance.processedByModel.includes("storytell")) return;

  const asset = knowledgeChange.knowledgeFull?.assetContext?.added?.[0];
  if (!asset) return;
  if (asset.lifecycleState !== AssetLifecycleStates.Ready) return;

  return prompt.prompt;
};

export const computeThreadData = (messages: ThreadMessage[]): ThreadStore["computed"] => {
  const res: ThreadStore["computed"] = {
    autosubmitCampaignPrompt: undefined,
    transformationCampaign: undefined,
  };

  const transformationId = getThreadTransformationId(messages);
  if (!transformationId) return res;

  const campaignPrompt = getThreadCampaignPrompt(transformationId);
  if (!campaignPrompt) return res;

  return {
    autosubmitCampaignPrompt: getThreadAutoSubmitCampaignPrompt(messages, campaignPrompt),
    transformationCampaign: campaignPrompt,
  };
};
