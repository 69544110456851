import { createContext, useContext } from "solid-js";
import type { Component, JSXElement } from "solid-js";
import type { Dictionary } from "~/domains/i18n/dictionary/en";
import { newWire } from "./factory";
import type { Dependencies, Flags, Services, Wire } from "./types";
import { SerializeServerValues } from "~/domains/environment";

/** defaultWire signals that the wire is not yet initialized. */
export const defaultWire: Wire = Object.freeze({
  metadata: {
    isDefault: true,
    createdAt: 0,
  },
  services: {} as Services,
  dependencies: {} as Dependencies,
  flags: {} as Flags,
  dict: {} as Dictionary,
});

export const WireContext = createContext(defaultWire);

/**
 * useWire provides access to dependencies, global state, and the ability to interact with domains. UI components
 * must call this function to gain access to application logic and persistent data.
 */
export const useWire = (): Wire => {
  const wire = useContext(WireContext) as Wire;
  if (!wire) {
    throw new Error("useWire must be used within a Store.Provider");
  }
  return wire;
};

export const WireProvider: Component<{ children: JSXElement }> = (props) => {
  const wire = newWire();
  return (
    <>
      <WireContext.Provider value={wire}>{props.children}</WireContext.Provider>
      <SerializeServerValues values={wire.services.environment.settings.values} />
    </>
  );
};
