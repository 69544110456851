import { ctxHeader } from "~/components/layouts/AppLayout/CtxHeader/CtxHeader.i18n.en";
import { identity } from "~/domains/identity/Identity.i18n.en";

export const dictionary = {
  ctxHeader,
  identity,
};

/**
 * Dictionary represents the authoritative source of translations for the application.
 */
export type Dictionary = typeof dictionary;
