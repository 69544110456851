import { stAnalytics } from "@repo/analytics";
import { codes, defaultCollectionName, getRequestClient, photoURL } from "@repo/client";
import { type Logger, Named } from "@repo/logger";
import { BrowserStorage } from "@repo/storage";
import type { useActor } from "@xstate/solid";
import { initializeApp } from "firebase/app";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  type User,
  type UserCredential,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  getAuth,
  getRedirectResult,
  isSignInWithEmailLink,
  linkWithCredential,
  linkWithRedirect,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithCredential,
} from "firebase/auth";
import type { Accessor } from "solid-js";
import { isServer } from "solid-js/web";
import type { Dictionary } from "~/domains/i18n/dictionary/en";
import type { LocaleKey } from "~/domains/i18n/types/types";
import type { identityEventFactory } from "~/domains/identity/machines";
import type { AuthenticatedIdentity } from "~/domains/identity/types";

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: APP_DOMAIN || FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
};

export const firebaseApp = () => initializeApp(firebaseConfig);

type SyncAuthWithAPIParams = {
  photoURL: string;
  deviceID: string;
  displayName: string;
  email: string;
  isEmailVerified: boolean;
  locale: string;
  userAgent: string;
  defaultOrganizationDisplayName: string;
  defaultOrganizationName: string;
  defaultOrganizationSlug: string;
  defaultProjectDisplayName: string;
  defaultProjectName: string;
  defaultProjectSlug: string;
};

const GUEST_EMAIL_ADDRESS = "guest@guest.storytell.ai" as const;

type FirebaseAuth = {
  /**
   * createUserWithCredentials creates a new user with the given email and password.
   * @param email
   * @param password
   */
  createUserWithCredentials: (email: string, password: string) => Promise<void>;
  /**
   * signInWithOAuth signs in a user using OAuth. It currently only supports Google.
   */
  signInWithOAuth: () => Promise<void>;
  /**
   * signs in a user using a magic link sent to their email.
   */
  signInWithMagicLink: (email: string) => Promise<void>;
  /**
   * Validase if the url is a valid email magic link
   */
  isURLMagicLink: (url: string) => boolean;
  /**
   * finishes sign in with magic link
   */
  finishSignInMagicLink: (email: string, url: string) => Promise<void>;
  /**
   * processOAuthRedirect processes the OAuth redirect result from Firebase. Firebase will return the user to whatever
   * page they were on when they initiated the OAuth flow. This function should be called on the page that the user is
   * redirected to.
   */
  processOAuthRedirect: () => Promise<void>;
  /**
   * getIdentityToken returns the cached identity token for the current user, if they are signed in. This token may
   * be passed to the API to authenticate the user.
   */
  getIdentityToken: () => string | undefined;

  /**
   * Signs out the user
   */
  signOut: () => Promise<void>;
};

export type FirebaseAppDeps = {
  locale: Accessor<LocaleKey>;
  getDeviceId: Accessor<string>;
  dictionary: Dictionary;
  eventFactory: ReturnType<typeof identityEventFactory>;
  firebase: ReturnType<typeof firebaseApp>;
  logger: Logger;
  send: ReturnType<typeof useActor>[1];
};

/**
 * firebaseAuth returns an object that can be used to authenticate users using Firebase.
 * @param deps
 */
export const firebaseAuth = (deps: FirebaseAppDeps): FirebaseAuth => {
  const logger = new Named(deps.logger, "firebaseAuth");
  const fb = getAuth(deps.firebase);

  // to avoid a stale token with stale claims and all sorts of other issues, we'll set up a little routine
  // to refresh it once in a while
  const refresher = () => {
    let schedule: NodeJS.Timeout | null = null;
    const refresh = async () => {
      if (!fb.currentUser) {
        logger.warn("no user to refresh token for");
        return;
      }
      logger.info("refreshing token");
      tokenCache.setToken(await fb.currentUser.getIdToken(true));
      schedule = null;
    };

    return {
      refresh,
      schedule: (seconds: number) => {
        schedule = setInterval(refresh, 1000 * seconds);
      },
    };
  };

  // We need to make a function available to services that call the API to allow them to get the
  // token for their requests. The client code needs to have a value without awaiting a promise.
  const identityTokenCache = () => {
    let cachedIdentityToken: undefined | string;
    return {
      clearToken: () => {
        cachedIdentityToken = undefined;
      },
      setToken(token: string) {
        cachedIdentityToken = token;
      },
      getIdentityToken() {
        return cachedIdentityToken;
      },
    };
  };
  const tokenCache = identityTokenCache();

  const createUserWithCredentials = async (email: string, password: string) => {
    await createUserWithEmailAndPassword(fb, email, password);
  };

  function getUserAgent(): string {
    if (typeof window !== "undefined" && typeof window.navigator !== "undefined") {
      return window.navigator.userAgent;
    }
    return "unknown";
  }

  /**
   * syncAuthWithAPI syncs the user's authentication state with the API. This function should be called whenever
   * the user's authentication state changes to be authenticated.
   *
   * @param params
   */
  const syncAuthWithAPI = async (params: SyncAuthWithAPIParams): Promise<AuthenticatedIdentity> => {
    const client = getRequestClient(tokenCache.getIdentityToken);
    logger.info("syncing auth with api", { params });
    const result = await client.controlplane.AuthSync({
      ...params,
      defaultOrganizationCollectionLabel: defaultCollectionName.DefaultOrganizationCollectionLabel,
      defaultPersonalCollectionLabel: defaultCollectionName.DefaultPersonalCollectionLabel,
      defaultFavoritesCollectionLabel: defaultCollectionName.DefaultFavoritesCollectionLabel,
      workflowVariant: "",
    });
    logger.info("auth sync complete", { result });
    if (result.code !== codes.OK) {
      logger.warn("auth sync failed", { result });
    }
    if (result.code === codes.OK && result.data.tokenRefreshRequired) {
      logger.info("api has set new claims -- will refresh firebase token");
      // Refresh the token if the API tells us to. This will happen if the API has set new custom claims on the user.
      // The custom claims will persist forever until the server tells the client to refresh the token again.
      if (fb.currentUser) {
        tokenCache.setToken(await fb.currentUser.getIdToken(true));
        logger.info("refreshed token");
      } else {
        logger.warn("no user to refresh token for");
      }
    }
    const user = result.data.user;
    const wCtx = result.data.workingContext;
    refresher().schedule(60 * 5); // refresh token every 5 minutes

    return {
      userId: user.id,
      displayName: user.displayName,
      email: user.email,
      emailVerified: params.isEmailVerified, // todo: fix API should be returning this.
      isAuthenticated: true,
      pictureURL: user.pictureURL,
      didSignUp: result.data.didSignUp,
      potentialScore: user.potentialScore,
      workingContext: {
        organizationId: wCtx.organizationId,
        tenantId: wCtx.tenantId,
        collectionTree: wCtx.CollectionTree ?? [],
        operationId: result.OperationID,
      },
      isGuest: user.email === GUEST_EMAIL_ADDRESS,
    };
  };

  /**x
   * signInWithOAuth signs in a user using OAuth. It currently only supports Google.
   */
  const signInWithOAuth = async () => {
    const childLogger = logger.child("signInWithOAuth");
    if (_LOG) childLogger.info("starting");
    if (!fb.currentUser) {
      if (_LOG) childLogger.info("Current user doesn't exist, aborting");
      return;
    }

    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    fb.languageCode = deps.locale();
    await linkWithRedirect(fb.currentUser, provider);
  };

  const prepareSyncAuthWithAPIParams = (result: User) => ({
    photoURL: result.photoURL || photoURL.DefaultGuestPhotoFQLURL,
    deviceID: deps.getDeviceId(),
    displayName: result.displayName || "Guest",
    email: result.email || GUEST_EMAIL_ADDRESS,
    isEmailVerified: result.emailVerified ?? false,
    locale: deps.locale(),
    userAgent: getUserAgent(),
    defaultOrganizationDisplayName: deps.dictionary.identity.newUserDefaults.organization.displayName,
    defaultOrganizationName: deps.dictionary.identity.newUserDefaults.organization.name,
    defaultOrganizationSlug: deps.dictionary.identity.newUserDefaults.organization.slug,
    defaultProjectDisplayName: deps.dictionary.identity.newUserDefaults.project.displayName,
    defaultProjectName: deps.dictionary.identity.newUserDefaults.project.name,
    defaultProjectSlug: deps.dictionary.identity.newUserDefaults.project.slug,
  });

  const signInWithMagicLink = async (email: string) => {
    const childLogger = logger.child("signInWithMagicLink");
    if (_LOG) childLogger.info("starting");
    fb.languageCode = deps.locale();
    await sendSignInLinkToEmail(fb, email, {
      url: `https://${APP_DOMAIN}/auth/finish`,
      handleCodeInApp: true,
    }).then(() => {
      BrowserStorage.setLastUsedMagicLinkEmail(email);
    });
  };

  const finishSignInMagicLink = async (email: string, url: string) => {
    const childLogger = logger.child("finishSignInMagicLink");
    if (_LOG) childLogger.info("starting");
    deps.send(deps.eventFactory.newDetectingEvent());

    if (!fb.currentUser) {
      if (_LOG) childLogger.info("No current user, aborting");
      return;
    }

    if (!fb.currentUser.isAnonymous) {
      if (_LOG) childLogger.info("The user isn't logged out / anonymous, aborting");
      return;
    }

    const emailCredential = EmailAuthProvider.credentialWithLink(email, url);

    let credential: UserCredential;

    let didSignUp = false;
    try {
      credential = await linkWithCredential(fb.currentUser, emailCredential);
      didSignUp = true;
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use") {
        credential = await signInWithCredential(fb, emailCredential);
        didSignUp = false;
      } else {
        throw error;
      }
    }

    BrowserStorage.removeLastUsedMagicLinkEmail();

    if (!emailCredential || !credential) {
      if (_LOG) childLogger.warn("credentials not found within result, aborting");
      return;
    }

    // establish the initial identity token
    const t = await fb.currentUser?.getIdToken();
    if (!t) {
      return;
    }
    tokenCache.setToken(t);

    const identity = await syncAuthWithAPI(prepareSyncAuthWithAPIParams(credential.user));
    childLogger.info("response from syncAuthWithAPI", { identity });

    try {
      await stAnalytics.track(didSignUp ? "sign_up_successful" : "log_in_successful", {
        email: identity.email,
        userId: identity.userId,
      });
      if (didSignUp) {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (window as any).dataLayer?.push("event", "conversion_event_signup", {
          email: identity.email,
          userId: identity.userId,
        });
      }
    } catch (error) {}

    deps.send(deps.eventFactory.newAuthenticatedEvent(identity));
  };

  const isURLMagicLink = (url: string) => isSignInWithEmailLink(fb, url);

  /**
   * processOAuthRedirect processes the OAuth redirect result from Firebase. Firebase will return the user to whatever
   * page they were on when they initiated the OAuth flow.
   */
  const processOAuthRedirect = async () => {
    const childLogger = logger.child("processOAuthRedirect");
    if (_LOG) childLogger.info("starting");
    deps.send(deps.eventFactory.newDetectingEvent());
    let result: UserCredential | null = null;

    let didSignUp = false;
    try {
      result = await getRedirectResult(fb);
      didSignUp = true;
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      // INFO: Handle multiple errors here when we add providers other than google
      // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#getredirectresult
      if (error.code === "auth/credential-already-in-use") {
        // The docs are wrong about this, there's no error.credential, but there's an error.customData
        const googleCredential = GoogleAuthProvider.credential(
          error.customData._tokenResponse.oauthIdToken,
          error.customData._tokenResponse.oauthAccessToken,
        );
        result = await signInWithCredential(fb, googleCredential);
        didSignUp = false;
      } else {
        throw error;
      }
    }
    if (!result) {
      if (_LOG) childLogger.info("redirectResult is null, aborting");
      return;
    }

    logger.info("OAuthRedirect obtained result", { result });

    const credential = GoogleAuthProvider.credentialFromResult(result);

    if (!credential) {
      if (_LOG) childLogger.warn("credentials not found within result, aborting");
      return;
    }

    // establish the initial identity token
    const t = await fb.currentUser?.getIdToken();
    if (!t) {
      // if (_LOG) childLogger.warn("token not found within result, aborting");
      return;
    }
    tokenCache.setToken(t);

    const identity = await syncAuthWithAPI(prepareSyncAuthWithAPIParams(result.user));
    childLogger.info("response from syncAuthWithAPI", { identity });

    try {
      await stAnalytics.track(didSignUp ? "sign_up_successful" : "log_in_successful", {
        email: identity.email,
        userId: identity.userId,
      });

      if (didSignUp) {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (window as any).dataLayer?.push("event", "conversion_event_signup", {
          email: identity.email,
          userId: identity.userId,
        });
      }
    } catch (error) {}

    deps.send(deps.eventFactory.newAuthenticatedEvent(identity));
  };

  const onUnauthenticated = async () => {
    tokenCache.clearToken();
    deps.send(deps.eventFactory.newSigningOutEvent());
    await signInAnonymously(fb);
  };

  // onAuthStateChanged is called whenever the user's authentication state changes.
  // It will be called automatically by Firebase.
  onAuthStateChanged(fb, async (user: User | null) => {
    if (isServer) return;

    const childLogger = logger.child("onAuthStateChanged");
    if (!user) {
      if (_LOG) childLogger.info("no user; signing out");
      await onUnauthenticated();
      return;
    }
    if (_LOG) childLogger.info("user detected", { user });
    const t = await fb.currentUser?.getIdToken();
    if (!t) {
      if (_LOG) childLogger.warn("token not found within result, aborting");
      // log exception here
      return;
    }
    tokenCache.setToken(t);
    const identity = await syncAuthWithAPI(prepareSyncAuthWithAPIParams(user));

    deps.send(deps.eventFactory.newAuthenticatedEvent(identity));
  });

  const signOut = () => {
    return firebaseSignOut(fb).then((r) => {
      stAnalytics.plugins.posthog.reset();
      return r;
    });
  };

  return {
    createUserWithCredentials,
    signInWithOAuth,
    signInWithMagicLink,
    isURLMagicLink,
    finishSignInMagicLink,
    processOAuthRedirect,
    getIdentityToken: () => tokenCache.getIdentityToken(),
    signOut,
  };
};
