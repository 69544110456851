import { formatMessageForHumans } from "./formatters";
import type { Logger } from "./types";

/**
 * Client implements the Logger interface and is used for logging on a client. It differs from the Server logger in
 * that it will typically not dispatch a message to the remote logging service for analysis. Instead, it will
 * typically only emit messages when the client is in development mode.
 */
export class Client implements Logger {
  constructor(
    readonly instanceId: string,
    readonly params?: Record<string, unknown>,
  ) {}

  error(
    errCode: string,
    caller: string,
    message: string,
    error?: Error | unknown,
    optionalParams?: Record<string, unknown>,
  ): void {
    console.error(message, error, optionalParams);
  }

  kind() {
    return "Console";
  }

  debug(caller: string, message: string, optionalParams?: Record<string, unknown>) {
    optionalParams
      ? console.debug(formatMessageForHumans(false, this.instanceId, caller, message), optionalParams)
      : console.debug(formatMessageForHumans(false, this.instanceId, caller, message));
  }

  info(caller: string, message: string, optionalParams?: Record<string, unknown>) {
    optionalParams
      ? console.info(formatMessageForHumans(false, this.instanceId, caller, message), optionalParams)
      : console.info(formatMessageForHumans(false, this.instanceId, caller, message));
  }

  log(caller: string, message: string, optionalParams?: Record<string, unknown>) {
    optionalParams
      ? console.log(formatMessageForHumans(false, this.instanceId, caller, message), optionalParams)
      : console.log(formatMessageForHumans(false, this.instanceId, caller, message));
  }

  trace(caller: string, message: string, optionalParams?: Record<string, unknown>) {
    optionalParams
      ? console.trace(formatMessageForHumans(false, this.instanceId, caller, message), optionalParams)
      : console.trace(formatMessageForHumans(false, this.instanceId, caller, message));
  }

  warn(caller: string, message: string, optionalParams?: Record<string, unknown>) {
    optionalParams
      ? console.warn(formatMessageForHumans(false, this.instanceId, caller, message), optionalParams)
      : console.warn(formatMessageForHumans(false, this.instanceId, caller, message));
  }

  cloneWithInstanceId(instanceId: string): Logger {
    return new Client(instanceId, this.params);
  }

  cloneWithParams(params: Record<string, unknown>, instanceId?: string): Logger {
    return new Client(instanceId ? instanceId : this.instanceId, Object.assign({}, this.params, params));
  }
}
