import type { Accessor } from "solid-js";
import {
  createCollection,
  createCollectionsStore,
  createFetchCollectionAssetsResource,
  createFetchCollectionTreeResource,
  getAllCollectionsFlattened,
  getColectionBreadcrumbs,
  getCollection,
  getCollectionByKind,
  getCollectionChildren,
  getCollectionChildrenIds,
  getCollectionDescendantsIds,
  getCollectionOrFail,
  getCollectionParents,
  getCollectionParentsIds,
  getCollectionTree,
  getCollectionTreeIds,
  getPersonalRootCollection,
  getUICollectionRootsLoaded,
  updateCollection,
  updateCollectionsBatch,
  updateCollectionsFromTree,
  updateInitialCollectionsTreeBasedOnWorkingContext,
} from "./collections.store";
import type { CollectionKind, CollectionSnapshot, CollectionTree } from "./collections.types";
import type { WorkingContext } from "../identity/types";

export const createCollectionsService = (deps: {
  getAuthToken: () => string | undefined;
  isIdentityReady: () => boolean;
}) => {
  const [store, setStore] = createCollectionsStore();

  const tree = createFetchCollectionTreeResource(setStore, deps.getAuthToken, deps.isIdentityReady);

  return {
    // TODO: better way to share resource data between components without resorting to a global resource
    // Global resources
    globalResourceTree: tree,

    // Getters
    getAllCollections: () => getAllCollectionsFlattened(store),
    getRootCollectionByKind: (kind: CollectionKind) => getCollectionByKind(store, kind),
    getRootsLoaded: () => getUICollectionRootsLoaded(store),
    getCollection: (collectionId: string) => getCollection(store, collectionId),
    getDataOrFail: (collectionId: string) => getCollectionOrFail(store, collectionId),
    getParentsIds: (collectionId: string) => getCollectionParentsIds(store, collectionId),
    getParents: (collectionId: string) => getCollectionParents(store, collectionId),
    getChildrenIds: (collectionId: string) => getCollectionChildrenIds(store, collectionId),
    getChildren: (collectionId: string) => getCollectionChildren(store, collectionId),
    getBreadcrumbs: (collectionId: string) => getColectionBreadcrumbs(store, collectionId),
    getTreeIds: (collectionId: string) => getCollectionTreeIds(store, collectionId),
    getTreeData: (collectionId: string) => getCollectionTree(store, collectionId),
    getCollectionDescendantsIds: (collectionId: string) => getCollectionDescendantsIds(store, collectionId),
    getPersonalRoot: () => getPersonalRootCollection(store),
    // Setters
    setCollection: (collection: CollectionSnapshot) => updateCollection(setStore, collection),
    setCollections: (collections: CollectionSnapshot[]) => updateCollectionsBatch(setStore, collections),
    setTree: (tree: CollectionTree) => updateCollectionsFromTree(setStore, tree),

    // Reactive listeners
    reactiveCollectionsFromWorkingContext: (wctx: Accessor<WorkingContext>) =>
      updateInitialCollectionsTreeBasedOnWorkingContext(setStore, wctx),

    // Server mutations
    apiCreateCollection: (parent: CollectionSnapshot, label: string, description?: string) =>
      createCollection(setStore, deps.getAuthToken, parent, label, description),

    // Resource creators
    resourceCollectionAssets: (collectionId: Accessor<string | undefined>) =>
      createFetchCollectionAssetsResource(setStore, deps.getAuthToken, collectionId),
    resourceCollectionTree: (isReady: Accessor<boolean>) =>
      createFetchCollectionTreeResource(setStore, deps.getAuthToken, isReady),
  };
};

export type CollectionsService = ReturnType<typeof createCollectionsService>;
