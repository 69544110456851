import { getRequestClient } from "@repo/client";
import { type Accessor, batch, createEffect, createResource } from "solid-js";
import { createStore, produce, reconcile } from "solid-js/store";
import { addArrayUnique } from "~/lib/arrays";
import { urls } from "~/lib/urls";
import type { WorkingContext } from "../identity/types";
import {
  CollectionKind,
  type CollectionSnapshot,
  type CollectionTree,
  type CollectionTreeIds,
  type CollectionsStore,
  type SetCollectionsStoreFunc,
} from "./collections.types";

// ▗▄▄▖ ▗▄▄▄▖ ▗▄▖ ▗▄▄▖ ▗▄▄▄▖
// ▐▌     █  ▐▌ ▐▌▐▌ ▐▌▐▌
//  ▝▀▚▖  █  ▐▌ ▐▌▐▛▀▚▖▐▛▀▀▘
// ▗▄▄▞▘  █  ▝▚▄▞▘▐▌ ▐▌▐▙▄▄▖

const initialStore = (): CollectionsStore => ({
  ids: {},
  parentOf: {},
  childOf: {},
  root: {},
  ui: {
    rootCollectionsLoaded: false,
  },
});

/**
 * Creates a reactive collections store instance.
 *
 * Simple wrapper around solid's `createStore` for now, could hold extra initializing logic in the future.
 */
export const createCollectionsStore = () => createStore<CollectionsStore>(initialStore());

// ▗▖  ▗▖▗▖ ▗▖▗▄▄▄▖ ▗▄▖ ▗▄▄▄▖▗▄▄▄▖ ▗▄▖ ▗▖  ▗▖ ▗▄▄▖
// ▐▛▚▞▜▌▐▌ ▐▌  █  ▐▌ ▐▌  █    █  ▐▌ ▐▌▐▛▚▖▐▌▐▌
// ▐▌  ▐▌▐▌ ▐▌  █  ▐▛▀▜▌  █    █  ▐▌ ▐▌▐▌ ▝▜▌ ▝▀▚▖
// ▐▌  ▐▌▝▚▄▞▘  █  ▐▌ ▐▌  █  ▗▄█▄▖▝▚▄▞▘▐▌  ▐▌▗▄▄▞▘

/**
 * Updates a single collection's data.
 * Ensures the `childOf` and `parentOf` hierarchical records are updated.
 */
export const updateCollection = (setStore: SetCollectionsStoreFunc, collection: CollectionSnapshot) => {
  const id = collection.id;
  batch(() => {
    const adjusted = { ...collection };
    if (adjusted.collectionKind === CollectionKind.Favorites) adjusted.label = "Starred";
    else if (adjusted.collectionKind === CollectionKind.Personal) adjusted.label = "Personal Collection";
    else if (adjusted.collectionKind === CollectionKind.Org) adjusted.label = "Shared Collection";
    setStore("ids", id, reconcile(adjusted));
    updateCollectionHierarchyFromPath(setStore, collection.path);
    const kind = collection.collectionKind as CollectionKind;
    if (Object.values(CollectionKind).includes(kind)) {
      setStore("root", kind, collection.id);
    }
  });
};

export const updateCollectionsBatch = (setStore: SetCollectionsStoreFunc, collections: CollectionSnapshot[]) => {
  batch(() => {
    for (const collection of collections) {
      updateCollection(setStore, collection);
    }
  });
};

/**
 * Updates multiple collections based on a hierarcy tree structure provided by the BE.
 * Ensures the `childOf` and `parentOf` hierarchical records are updated.
 */
export const updateCollectionsFromTree = (setStore: SetCollectionsStoreFunc, tree: CollectionTree) => {
  // Walk the full hierarchy tree of collections and update the data for each one
  // Using breadth-first-search instead of recursive since it keeps the function flatter
  // and the reactivity implications simpler
  const stack = [tree];
  batch(() => {
    while (stack.length > 0) {
      const current = stack.pop();
      if (!current) break;

      const { collection, children } = current;
      stack.push(...(children || []));

      // No need to update the hierarchy in here since updateCollection takes care of that through the path
      updateCollection(setStore, collection);
    }
  });
};

export const updateCollectionTreesBatch = (setStore: SetCollectionsStoreFunc, trees: CollectionTree[]) => {
  batch(() => {
    for (const tree of trees) {
      updateCollectionsFromTree(setStore, tree);
    }
  });
};

/**
 * Updates the `childOf` and `parentOf` hierarchical records based on a breadcrumb-like path of collection ids.
 */
const updateCollectionHierarchyFromPath = (setStore: SetCollectionsStoreFunc, path: string[]) => {
  return setStore(
    produce((store) => {
      path.forEach((parent, index) => {
        const child = path[index + 1];
        if (!child) return;

        // Add child that parent is `parentOf`
        if (!store.parentOf[parent]) {
          store.parentOf[parent] = [];
        }
        store.parentOf[parent] = addArrayUnique(store.parentOf[parent], child);

        // Add parent that child is `childOf`
        if (!store.childOf[child]) {
          store.childOf[child] = [];
        }
        store.childOf[child] = addArrayUnique(store.childOf[child], parent);
      });
    }),
  );
};

// ▗▄▄▖ ▗▄▄▄▖▗▄▄▄▖▗▄▄▄▖▗▄▄▄▖▗▄▄▖  ▗▄▄▖
// ▐▌   ▐▌     █    █  ▐▌   ▐▌ ▐▌▐▌
// ▐▌▝▜▌▐▛▀▀▘  █    █  ▐▛▀▀▘▐▛▀▚▖ ▝▀▚▖
// ▝▚▄▞▘▐▙▄▄▖  █    █  ▐▙▄▄▖▐▌ ▐▌▗▄▄▞▘

export const getCollection = (store: CollectionsStore, collectionId: string) => store.ids[collectionId];

export const getCollectionByKind = (store: CollectionsStore, kind: CollectionKind) =>
  getCollection(store, store.root[kind] || "");

export const getCollectionOrFail = (store: CollectionsStore, collectionId: string) => {
  const collection = getCollection(store, collectionId);
  if (!collection) throw Error("Collection not found");
  return collection;
};

export const getCollectionChildrenIds = (store: CollectionsStore, collectionId: string) =>
  store.parentOf[collectionId] || [];

export const getCollectionChildren = (store: CollectionsStore, collectionId: string) =>
  getCollectionChildrenIds(store, collectionId).map((childId) => getCollectionOrFail(store, childId));

export const getCollectionParentsIds = (store: CollectionsStore, collectionId: string) =>
  store.childOf[collectionId] || [];

export const getCollectionPath = (store: CollectionsStore, collectionId: string): CollectionSnapshot[] | undefined => {
  const path = getCollection(store, collectionId)?.path;
  if (!path) return;

  return path.map((p) => getCollectionOrFail(store, p));
};

export const getPersonalRootCollection = (store: CollectionsStore) =>
  getCollectionByKind(store, CollectionKind.Personal);

export const getColectionBreadcrumbs = (store: CollectionsStore, collectionId: string): Breadcrumb[] | undefined =>
  getCollectionPath(store, collectionId)?.map((c) => ({ label: c.label, href: urls.collection(c.id) }));

export const getCollectionParents = (store: CollectionsStore, collectionId: string) =>
  getCollectionChildrenIds(store, collectionId).map((childId) => getCollectionOrFail(store, childId));

export const getCollectionTreeIds = (store: CollectionsStore, collectionId: string) => {
  const root: CollectionTreeIds = {
    collection: collectionId,
    children: [],
  };
  const stack = [root];
  while (stack.length > 0) {
    const node = stack.pop();
    if (!node) break;
    node.children = getCollectionChildrenIds(store, node.collection)
      .map((c) => ({ collection: c, children: [] }))
      .reverse();
    stack.push(...(node.children || []));
  }
  return root;
};

export const getCollectionTree = (store: CollectionsStore, collectionId: string) => {
  const root: CollectionTree = {
    collection: getCollectionOrFail(store, collectionId),
    children: [],
  };
  const stack = [root];
  while (stack.length > 0) {
    const node = stack.pop();
    if (!node) break;
    node.children = getCollectionChildren(store, node.collection.id)
      .map((c) => ({ collection: c, children: [] }))
      .reverse();
    stack.push(...(node.children || []));
  }
  return root;
};

export const getCollectionDescendantsIds = (store: CollectionsStore, collectionId: string) => {
  const result = [collectionId];
  const children = [...(getCollectionChildrenIds(store, collectionId) || [])];
  while (children.length > 0) {
    const child = children.pop();
    if (!child) break;
    result.push(child);
    children.push(...getCollectionChildrenIds(store, child));
  }
  return result;
};

export const getAllCollectionsFlattened = (store: CollectionsStore) => Object.values(store.ids);

export const getUICollectionRootsLoaded = (store: CollectionsStore) => store.ui.rootCollectionsLoaded;

//  ▗▄▖  ▗▄▄▖▗▖  ▗▖▗▖  ▗▖ ▗▄▄▖
// ▐▌ ▐▌▐▌    ▝▚▞▘ ▐▛▚▖▐▌▐▌
// ▐▛▀▜▌ ▝▀▚▖  ▐▌  ▐▌ ▝▜▌▐▌
// ▐▌ ▐▌▗▄▄▞▘  ▐▌  ▐▌  ▐▌▝▚▄▄▖

export const createFetchCollectionAssetsResource = (
  setStore: SetCollectionsStoreFunc,
  getAuthToken: () => string | undefined,
  collectionId: Accessor<string | undefined>,
) => {
  const client = getRequestClient(getAuthToken);
  const [data, methods] = createResource(collectionId, async (id) => {
    if (!id) return;

    const response = await client.controlplane.GetAssetsByCollectionID(id, {
      Limit: 50,
      Offset: 0,
      Sort: [],
    });

    const collections = Object.values(response.data.result.collections).map((c) => c.data);
    updateCollectionsBatch(setStore, collections);
    return response;
  });

  return [data, methods] as const;
};

export const createFetchCollectionTreeResource = (
  setStore: SetCollectionsStoreFunc,
  getAuthToken: () => string | undefined,
  isReady: Accessor<boolean>,
) => {
  const client = getRequestClient(getAuthToken);
  const [data, methods] = createResource(isReady, async () => {
    const response = await client.controlplane.GetCollectionTree();
    updateCollectionTreesBatch(setStore, response.data);
    return response;
  });

  return [data, methods] as const;
};

export const createCollection = async (
  setStore: SetCollectionsStoreFunc,
  getAuthToken: () => string | undefined,
  parent: CollectionSnapshot,
  label: string,
  description?: string,
) => {
  const client = getRequestClient(getAuthToken);
  const res = await client.controlplane.CreateCollection(parent.id, {
    label,
    description: description || "",
    icon: "",
    kind: "child",
    parent: parent.id,
    organizationContext: {
      organizationId: parent.organizationId,
      tenantId: parent.tenantId,
    },
  });

  updateCollection(setStore, res.data);
  return res;
};

// ▗▖  ▗▖▗▄▄▄▖ ▗▄▄▖ ▗▄▄▖
// ▐▛▚▞▜▌  █  ▐▌   ▐▌
// ▐▌  ▐▌  █   ▝▀▚▖▐▌
// ▐▌  ▐▌▗▄█▄▖▗▄▄▞▘▝▚▄▄▖

/**
 * The top 3 root collections are coming from the auth sync working context
 *
 * This helper function will listen to changes in the working context and update them
 */
export const updateInitialCollectionsTreeBasedOnWorkingContext = (
  setStore: SetCollectionsStoreFunc,
  wctx: Accessor<WorkingContext>,
) => {
  createEffect(() => {
    const { collectionTree } = wctx();

    batch(() => {
      if (collectionTree.length !== 0) {
        setStore("ui", "rootCollectionsLoaded", true);
      }
      for (const tree of collectionTree) {
        updateCollectionsFromTree(setStore, tree);
      }
    });
  });
};
