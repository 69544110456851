// import type { ExclusiveEventHintOrCaptureContext } from "@sentry/core/types/utils/prepareEvent";
// import * as SentryNode from "@sentry/node?server";

/**
 * Explicitly send errors to Sentry, both on server and client
 */
export const captureException = (
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  exception: any,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  hint?: any,
) => {
  if (import.meta.env.SSR) {
    // return SentryNode.captureException(exception, hint);
    return;
  }
  // @ts-ignore
  if (!window?.Sentry) return;

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  (window as any).Sentry.captureException(exception, hint);
  // return SentryBrowser.captureException(exception, hint);
};
