/**
 * formatMessageForHumans formats a message so it has improved readability for humans. It will have a time
 * encoding to help with debugging and a color code to help differentiate between different callers.
 * @param isServer
 * @param instanceId
 * @param caller
 * @param message
 */
export function formatMessageForHumans(isServer: boolean, instanceId: string, caller: string, message: string) {
  if (isServer) return `${instanceId} @${caller} ${message}`;

  const colorCode = getColorCode(hashText(extractPrefix(caller)));
  return `${colorCode}${performance.now().toFixed(1)} @${caller} ${message}`;
}

const colors = [
  "\x1b[31m", // Red
  "\x1b[32m", // Green
  "\x1b[33m", // Yellow
  "\x1b[34m", // Blue
  "\x1b[36m", // Cyan
  "\x1b[91m", // Light Red
  "\x1b[92m", // Light Green
  "\x1b[93m", // Light Yellow
  "\x1b[94m", // Light Blue
  "\x1b[96m", // Light Cyan
];

const getColorCode = (hash: number): string => {
  return colors[hash % colors.length] || "\x1b[0m";
};

const extractPrefix = (text: string): string => {
  if (text.indexOf(".") !== -1) {
    return text.split(".")[0] ?? text;
  }
  return text;
};

const hashText = (text: string): number => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i);
    hash = (hash << colors.length) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

/**
 * convertOptionalParams converts optional parameters to a Record<string, unknown> or undefined if there are no
 * optional parameters.
 * @param optionalParams
 */
export const convertOptionalParams = (optionalParams?: unknown): Record<string, unknown> | undefined => {
  if (!optionalParams) return undefined;
  if (typeof optionalParams !== "object") return undefined;
  return Object.fromEntries(Object.entries(optionalParams).map(([key, value]) => [key.toString(), value]));
};
