import { createStore, reconcile } from "solid-js/store";
import { cookieStorage, makePersisted } from "@solid-primitives/storage";
import type { JSX } from "solid-js/web/types/jsx";

type SidebarOpts = {
  closed: boolean;
  size: number;
};
type AppLayoutOpts = {
  enableEasing: boolean;
  leftSidebar: SidebarOpts;
  rightSidebar: SidebarOpts;
};

export const LEFT_SIDEBAR_SIZE = 240;
export const RIGHT_SIDEBAR_SIZE = 260;

export const useAppLayoutService = () => {
  // TODO: Store and load data from server instead of cookies
  const [data, setData] = makePersisted(
    createStore<AppLayoutOpts>({
      enableEasing: true,
      leftSidebar: {
        closed: false,
        size: LEFT_SIDEBAR_SIZE,
      },
      rightSidebar: {
        closed: false,
        size: RIGHT_SIDEBAR_SIZE,
      },
    }),
    {
      name: "appLayoutOptions:v1",
      storage: cookieStorage,
      storageOptions: { expires: new Date(+new Date() + 3.1536e12) }, // 100-years
    },
  );

  const resizeSidebarDOMProps = (
    targetSidebar: "leftSidebar" | "rightSidebar",
  ): JSX.HTMLAttributes<HTMLDivElement> => ({
    onMouseDown: (e) => {
      const element = e.target;
      let sidebarStart = targetSidebar === "leftSidebar" ? 0 : element.getBoundingClientRect().left;
      const onMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        document.documentElement.classList.add("cursor-ew-resize");

        const direction = targetSidebar === "leftSidebar" ? 1 : -1;

        const delta = (e.clientX - sidebarStart) * direction;
        const size = data[targetSidebar].size;
        const newSize = size + delta;
        const clamped = Math.max(150, Math.min(500, newSize));

        const clampedStartPos = direction === 1 ? 150 : document.documentElement.clientWidth - 150;

        if ((direction === 1 && e.clientX < 150) || (direction === -1 && e.clientX > clampedStartPos)) {
          sidebarStart = clampedStartPos;
          setData(
            reconcile({
              ...data,
              enableEasing: true,
              [targetSidebar]: { size: 0, closed: true },
            }),
          );
          return;
        }
        if (clamped !== size) {
          sidebarStart = e.clientX;
          setData(
            reconcile({
              ...data,
              enableEasing: false,
              [targetSidebar]: { size: clamped, closed: false },
            }),
          );
        }
      };
      const onMouseUp = () => {
        setData("enableEasing", true);
        document.documentElement.classList.remove("cursor-ew-resize");
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      };

      e.preventDefault();
      sidebarStart = e.clientX;
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    },
  });

  return {
    data,
    setData,
    resizeDOMProps: resizeSidebarDOMProps,
  };
};
