export const codes = {
  OK: "ok",
  Canceled: "canceled",
  Unknown: "unknown",
  InvalidArgument: "invalid_argument",
  DeadlineExceeded: "deadline_exceeded",
  NotFound: "not_found",
  AlreadyExists: "already_exists",
  PermissionDenied: "permission_denied",
  ResourceExhausted: "resource_exhausted",
  FailedPrecondition: "failed_precondition",
  Aborted: "aborted",
  OutOfRange: "out_of_range",
  Unimplemented: "unimplemented",
  Internal: "internal",
  Unavailable: "unavailable",
  DataLoss: "data_loss",
  Unauthenticated: "unauthenticated",
} as const;
