import { assign, createActor, fromPromise, setup } from "xstate";

export enum RetryMachineState {
  Reset = "reset",
  Idle = "idle",
  Try = "try",
  Retrying = "retrying",
  Success = "success",
  Failure = "failure",
}

export type RetryTryEvent = {
  type: "retry.try";
};

export type RetryResetEvent = {
  type: "retry.reset";
};

export type RetryRetryingEvent = {
  type: "retry.retrying";
};

export type RetrySuccessEvent<T> = {
  type: "retry.success";
  data?: T;
  onSuccess: (data?: T) => void;
};

export type RetryFailureEvent = {
  type: "retry.failure";
  error?: Error;
  onFailure: (error?: Error) => void;
};

export type RetryMachineEvents<T> =
  | RetryTryEvent
  | RetryRetryingEvent
  | RetrySuccessEvent<T>
  | RetryFailureEvent
  | RetryResetEvent;

export type RetryMachineContext<T> = {
  retries: number;
  retryDelay: number;
  hasTried: boolean;
  result?: T;
  error?: Error;
};

type Options<T> = {
  maxRetries: number;
  retryDelay?: number;
  action: (retries: number) => Promise<T>;
  onSuccess: (result?: T) => Promise<void>;
  onFailure: (error?: Error) => Promise<void>;
};

export const newRetryMachine = <T>(options: Options<T>) => {
  const initialContext: RetryMachineContext<T> = {
    retries: 0,
    hasTried: false,
    retryDelay: options.retryDelay ?? 0,
  };

  return setup({
    types: {
      context: {} as RetryMachineContext<T>,
      events: {} as RetryMachineEvents<T>,
    },
    actors: {
      action: fromPromise(async ({ input }: { input: { retries: number } }) => options.action(input.retries)),
      onSuccess: fromPromise(async ({ input }: { input: { data?: T } }) => options.onSuccess(input.data)),
      onFailure: fromPromise(async ({ input }: { input: { error?: Error } }) => options.onFailure(input.error)),
    },
    delays: {
      retryDelay: ({ context }) => context.retryDelay,
    },
  }).createMachine({
    id: "retryMachine",
    initial: RetryMachineState.Idle,
    context: initialContext,
    on: {
      "retry.reset": {
        target: `.${RetryMachineState.Idle}`,
      },
    },
    states: {
      idle: {
        on: {
          "retry.try": {
            target: RetryMachineState.Try,
            actions: assign({
              retries: 0,
            }),
          },
        },
      },
      try: {
        entry: assign({
          retries: ({ context }) => (context.hasTried ? context.retries + 1 : 0),
          hasTried: true,
        }),
        invoke: {
          src: "action",
          input: ({ context }) => ({ retries: context.retries }),
          onDone: {
            target: RetryMachineState.Success,
            actions: assign({
              result: ({ event }) => event.output,
            }),
          },
          onError: [
            {
              target: RetryMachineState.Retrying,
              guard: ({ context }) => context.retries < options.maxRetries,
            },
            {
              target: RetryMachineState.Failure,
              actions: assign({
                error: ({ event }): Error => event.error as Error,
              }),
            },
          ],
        },
      },
      retrying: {
        after: {
          retryDelay: { target: "try" },
        },
      },
      success: {
        invoke: {
          src: "onSuccess",
          input: ({ context }) => ({ data: context.result }),
        },
      },
      failure: {
        invoke: {
          src: "onFailure",
          input: ({ context }) => ({ error: context.error }),
        },
      },
    },
  });
};

/**
 * ## retryMachine
 *
 * Creates a new retry machine.
 *
 * @param {Object} options
 * * `maxRetries` (number): The maximum number of retries **after** the initial attempt.
 * * `retryDelay` (number): The delay between retries.
 * * `action` (function): The action to retry.
 * * `onSuccess` (function): The success callback.
 * * `onFailure` (function): The failure callback.
 * @returns
 * * `actor` (Actor): The actor for the retry machine.
 * * `start` (function): The function to commence retries.
 * * `reset` (function): The function to reset to the retry machine.
 * @example
 * ```ts
 * const { actor, start, reset } = useRetryMachine<Response>({
 * 	maxRetries: 3,
 * 	action: async (tryCount: number) => fetch("https://api.example.com"),
 * 	onSuccess: async (result: Response) => console.log("success"),
 * 	onFailure: async (error: Error) => console.log("failure")
 * });
 * actor.subscribe((state) => { console.log(state.value); });
 * start();
 * ```
 */
export const useRetryMachine = <T>(options: Options<T>) => {
  const actor = createActor(newRetryMachine(options));
  actor.start();

  const start = () => {
    actor.send({ type: "retry.try" });
  };

  const reset = () => {
    actor.send({ type: "retry.reset" });
  };

  return {
    actor,
    start,
    reset,
  };
};

export type RetryMachineActor<T> = ReturnType<typeof createActor<ReturnType<typeof newRetryMachine<T>>>>;
