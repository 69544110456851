import type { curator } from "./encore.gen";
import { ThreadMessageKinds } from "./thread_message_kinds.gen";

export type ThreadMessage =
  | curator.MessageTextV1
  | curator.MessagePromptV1
  | curator.MessagePromptV2
  | curator.MessagePromptTransformationV1
  | curator.MessagePromptTransformationV2
  | curator.MessageKnowledgeV1
  | MessageInsertFileUpload
  | MessageInsertSignUpLimit;

// Thread inserts
export type MessageInsert = {
  kind: "thread-insert";
  insertType: string;
  // Message id not needed, adding it for compatibility with BE messages
  messageId: string;
  label: string;
};

export type MessageInsertFileUpload = MessageInsert & {
  insertType: "file-upload";
};

export type MessageInsertSignUpLimit = MessageInsert & {
  insertType: "sign-up-limit";
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function isThreadInsert(obj: any): obj is MessageInsert {
  return obj && obj.kind === "thread-insert";
}

export function isMessageInsertFileUpload(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  obj: any,
): obj is MessageInsertFileUpload {
  return obj && obj.kind === "thread-insert" && obj.insertType === "file-upload";
}
export function isMessageInsertSignUpLimit(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  obj: any,
): obj is MessageInsertFileUpload {
  return obj && obj.kind === "thread-insert" && obj.insertType === "sign-up-limit";
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export function isMessageTextV1(obj: any): obj is curator.MessageTextV1 {
  return obj && obj?.kind === ThreadMessageKinds.MessageKindTextV1;
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export function isMessagePromptV1(obj: any): obj is curator.MessagePromptV1 {
  return obj && obj?.kind === ThreadMessageKinds.MessageKindPromptV1;
}

export function isMessageKnowledgeV1(
  // biome-ignore lint/suspicious/noExplicitAny: typeguard
  obj: any,
): obj is curator.MessageKnowledgeV1 {
  return obj && obj?.kind === ThreadMessageKinds.MessageKindKnowledgeChangeV1;
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export function isMessagePrompt(obj: any): obj is curator.MessagePromptV2 {
  if (!obj) return false;
  switch (obj.kind) {
    case ThreadMessageKinds.MessageKindPromptV1:
    case ThreadMessageKinds.MessageKindPromptV2:
    case ThreadMessageKinds.MessageKindPromptTransformationV1:
    case ThreadMessageKinds.MessageKindPromptTransformationV2:
      return true;
    default:
      return false;
  }
}

export type ThreadMessageKindObject = {
  type: string;
};

export function isHasThreadMessageType(
  // biome-ignore lint/suspicious/noExplicitAny: typeguard
  obj: any,
): obj is ThreadMessageKindObject {
  return obj && typeof obj?.kind === "string";
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export function isThreadMessage(obj: any): obj is ThreadMessage {
  return obj && typeof obj?.kind === "string" && Object.values(ThreadMessageKinds).includes(obj.kind);
}
