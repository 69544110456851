import { type Accessor, createSignal } from "solid-js";

export interface FeedbackService {
  isFeedbackPanelOpen: Accessor<boolean>;
  openFeedbackPanel: (content?: string) => void;
  closeFeedbackPanel: () => void;
  feedbackContent: Accessor<string>;
}

export const useFeedbackService = (): FeedbackService => {
  const [isFeedbackPanelOpen, setIsFeedbackPanelOpen] = createSignal(false);
  const [feedbackContent, setFeedbackContent] = createSignal("");
  return {
    isFeedbackPanelOpen,
    feedbackContent,
    openFeedbackPanel: (content?: string) => {
      if (content) setFeedbackContent(`Describe what you're experiencing and your thoughts on "${content}".\n\n`);
      setIsFeedbackPanelOpen(true);
    },
    closeFeedbackPanel: () => {
      setFeedbackContent("");
      setIsFeedbackPanelOpen(false);
    },
  };
};
