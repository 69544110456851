import type { APIError, controlplane, operations } from "@repo/client";
import type { Logger } from "@repo/logger";
import { assign, setup } from "xstate";
import type { OnReceive } from "../types";
import type { SendToServerEvent, WsEvents } from "./wsEvents";

type Message = controlplane.ServerEnvelope | operations.Response<string>;
type MessageExpanded = Message & {
  dataExpanded: Record<string, unknown>;
};

export type WebSocketStore = {
  connectCount: number;
  disconnectCount: number;
  envelopes: Message[];
  errorCount: number;
  isConnected: boolean;
  messageIn: number;
  messagesOut: number;
  errorMessages: APIError[];
  clearedErrors: APIError[];
};

export type WebSocketStoreExpanded = {
  connectCount: number;
  disconnectCount: number;
  envelopes: MessageExpanded[];
  errorCount: number;
  isConnected: boolean;
  messageIn: number;
  messagesOut: number;
  errorMessages: APIError[];
  clearedErrors: APIError[];
};

const initialContext = (): WebSocketStore => ({
  isConnected: false,
  envelopes: [],
  connectCount: 0,
  disconnectCount: 0,
  messageIn: 0,
  messagesOut: 0,
  errorCount: 0,
  errorMessages: [],
  clearedErrors: [],
});

export enum WebsocketMachineStates {
  Start = "start",
  Connected = "connected",
  Disconnected = "disconnected",
}

let websocketConnection: WebSocket | undefined = undefined;
export const setWebsocket = (ws: WebSocket) => {
  websocketConnection = ws;
};

export const removeWebsocket = () => {
  websocketConnection = undefined;
};

type Dependencies = {
  logger: Logger;
  onReceiveMessage: OnReceive;
};

interface WebSocketEvent {
  envelope: controlplane.ServerEnvelope;
  timestamp: number;
}

function dispatchWebSocketMessage(event: WebSocketEvent, websocketConnection: WebSocket) {
  const maxRetryTime = 60_000;
  let queuedEvents: WebSocketEvent[] = [];
  let retryInterval: NodeJS.Timeout | null = null;

  function sendQueuedEvents() {
    for (const e of queuedEvents) {
      websocketConnection.send(JSON.stringify(e.envelope));
    }
    queuedEvents = [];
    if (retryInterval) clearInterval(retryInterval);
  }

  if (websocketConnection.readyState === WebSocket.OPEN) {
    websocketConnection.send(JSON.stringify(event.envelope));
  } else {
    queuedEvents.push(event);
    if (retryInterval === null) {
      retryInterval = setInterval(() => {
        if (websocketConnection.readyState === WebSocket.OPEN) {
          sendQueuedEvents();
        } else if (Date.now() - event.timestamp > maxRetryTime) {
          if (retryInterval) clearInterval(retryInterval);
          alert("Failed to send message due to timeout connecting to websocket");
        }
      }, 500);
    }
  }
}

export const newWebsocketMachine = (deps: Dependencies) => {
  let queue: SendToServerEvent[] = [];

  return setup({
    types: {
      context: {} as WebSocketStore,
      events: {} as WsEvents,
    },
    actions: {
      broadcast: (_, params: { response: operations.Response<string> }) => {
        // deps.logger.info("broadcast", "broadcasting", {
        //   response: params.response,
        // });
        deps.onReceiveMessage(params.response.eventKey, params.response);
      },
    },
  }).createMachine({
    id: "wsMachine",
    initial: "start",
    context: initialContext(),
    states: {
      start: {
        on: {
          "ws.connected": {
            target: "connected",
            actions: assign({
              connectCount: ({ context }) => context.connectCount + 1,
              isConnected: true,
            }),
          },
          "ws.clearErrors": {
            actions: [
              assign({
                clearedErrors: ({ context }) => context.errorMessages,
                errorMessages: [],
                errorCount: 0,
              }),
            ],
          },
          "ws.send": {
            actions: [
              ({ event }) => {
                queue.push(event);
              },
            ],
          },
        },
      },
      disconnected: {
        on: {
          "ws.connected": {
            target: "connected",
          },
          "ws.clearErrors": {
            actions: [
              assign({
                clearedErrors: ({ context }) => context.errorMessages,
                errorMessages: [],
                errorCount: 0,
              }),
            ],
          },
          "ws.send": {
            actions: [
              ({ event }) => {
                console.log("web socket is disconnected, putting event in queue", event.envelope);
                queue.push(event);
              },
            ],
          },
        },
      },
      connected: {
        entry: [
          assign({
            envelopes: ({ context }) => [...context.envelopes, ...queue.map((e) => e.envelope)],
            messagesOut: ({ context }) => context.messagesOut + queue.length,
          }),
          ({ context }) => {
            if (queue.length === 0) return;

            if (!websocketConnection) {
              console.error("No websocket connection found", context);
              alert("No websocket connection found");
              return;
            }
            deps.logger.info("wsMachine", `flushing events queue: ${queue.length} events`);
            for (const event of queue) {
              deps.logger.info("wsMachine", "sending queued message", {
                envelope: event.envelope,
              });
              dispatchWebSocketMessage(
                {
                  envelope: event.envelope,
                  timestamp: Date.now(),
                },
                websocketConnection,
              );
            }

            queue = [];
          },
        ],
        on: {
          "ws.receiveError": {
            actions: [
              assign({
                errorCount: ({ context }) => context.errorCount + 1,
                errorMessages: ({ context, event }) => [...context.errorMessages, event.message] as APIError[],
              }),
            ],
          },
          "ws.disconnected": {
            target: "disconnected",
            actions: assign({
              disconnectCount: ({ context }) => context.disconnectCount + 1,
              isConnected: false,
            }),
          },
          "ws.clearErrors": {
            actions: [
              assign({
                clearedErrors: ({ context }) => context.errorMessages,
                errorMessages: [],
                errorCount: 0,
              }),
            ],
          },
          "ws.send": {
            actions: [
              assign({
                envelopes: ({ event, context }) => [...context.envelopes, event.envelope],
                messagesOut: ({ context }) => context.messagesOut + 1,
              }),
              ({ context, event }) => {
                if (!websocketConnection) {
                  console.error("No websocket connection found", context, event);
                  alert("No websocket connection found");
                  return;
                }
                deps.logger.info("wsMachine", "sending message", {
                  envelope: event.envelope,
                });
                dispatchWebSocketMessage(
                  {
                    envelope: event.envelope,
                    timestamp: Date.now(),
                  },
                  websocketConnection,
                );
              },
            ],
          },
          "ws.receive": {
            actions: [
              assign({
                envelopes: ({ event, context }) => [...context.envelopes, event.operation],
                messageIn: ({ context }) => context.messageIn + 1,
              }),
              {
                type: "broadcast",
                params: ({ event }) => ({ response: event.operation }),
              },
            ],
          },
        },
      },
    },
  });
};
