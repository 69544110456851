import { captureException } from "@repo/observability";
import commonEmails from "email-providers/all.json";

const eduDomainRegex = /\.(edu|sch|ac|academy|college|education|institute|school|university)/;
const govDomainRegex = /\.gov/;

export const getEmailTypeFromDomain = (email: string): "business" | "personal" | "education" | "government" => {
  if (email.endsWith("@guest.storytell.ai")) return "personal";
  try {
    const domain = email.split("@").pop();
    if (!domain) return "business";
    if (eduDomainRegex.test(domain)) return "education";
    if (govDomainRegex.test(domain)) return "government";
    if (!commonEmails.some((e) => e.endsWith(domain))) return "business";
  } catch (error) {
    captureException(error);
    console.error(error);
  }
  return "personal";
};
