export const ctxHeader = {
  tabs: {
    smartChat: "SmartChat",
    knowledge: "Knowledge",
    actions: "Actions",
    insights: "Insights",
    settings: "Settings",
    analytics: "Analytics",
    security: "Security",
  },
};

export type CtxHeaderDictionary = typeof ctxHeader;
